'use client';

import React, { useRef, useState } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import classNames from 'classnames';
import ContactButton from '@/components/ContactButton';
import { ButtonSize, ButtonVariants } from '@/components/Button';
import animationData from '#/../public/media/ellipses.json';
import styles from './HomeAudio.module.scss';

const HomeAudio = () => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const playerRef = useRef<HTMLAudioElement>(null);

  const handlePlay = () => {
    playerRef.current && playerRef.current.play();
    setIsPlaying(true);
    if (lottieRef.current) {
      lottieRef.current.play();
      lottieRef.current.setSpeed(2);
    }
    !isStarted && setIsStarted(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
    lottieRef.current && lottieRef.current.pause();
    playerRef.current && playerRef.current.pause();
  };

  return (
    <div className='container'>
      <section className={styles.audio}>
        <div className={styles.main}>
          <h3 className={styles.title}>Become a Pioneer in Your Industry Today</h3>
          <ContactButton
            className={classNames(styles.contactButton, styles.contactButton_desktop)}
          >
            Contact us
          </ContactButton>
        </div>
        <div className={styles.info}>
          <p className={styles.text}>We bring the full power of the human voice to modern businesses.</p>
          <p className={styles.text}>
            Our voice AI technology is guaranteed to take or make more calls, be more efficient and will transform
            your business, exploding your bottom line.
          </p>
          <ContactButton
            className={classNames(styles.contactButton, styles.contactButton_mobile)}
            variant={ButtonVariants.PRIMARY}
            size={ButtonSize.SMALL}
          >
            Contact us
          </ContactButton>
        </div>
        <div className={styles.playBlock}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            ref={playerRef}
            className={styles.assistant__voice}
            hidden
            onEnded={() => {
              setIsPlaying(false);
              setIsStarted(false);
              lottieRef.current && lottieRef.current.stop();
            }}
          >
            <source
              src='/media/Main.mp3'
              type='audio/mp3'
            />
          </audio>
          <div className={classNames(styles.playBlock__soundBar, {
            [styles.paused]: !isPlaying,
          })}
          >
            <Lottie
              animationData={animationData}
              autoplay={false}
              className={classNames(styles.ellipses, {
                [styles.hidden]: !isPlaying,
              })}
              lottieRef={lottieRef}
            />
            {new Array(5).fill(null).map((item, index) => (
              <div // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.soundBar}
              />
            ))}
            <button
              type='button'
              aria-label='play audio about xplobiz'
              className={styles.playButton}
              onClick={() => {
                isPlaying ? handlePause() : handlePlay();
              }}
            >
              {isPlaying ? (
                <svg>
                  <use
                    xlinkHref='/media/pause-button.svg#pauseSVG'
                    href='/media/pause-button.svg#pauseSVG'
                  />
                </svg>
              ) : (
                <svg>
                  <use
                    xlinkHref='/media/play-button.svg#playButton'
                    href='/media/play-button.svg#playButton'
                  />
                </svg>
              )}
            </button>
            {new Array(5).fill(null).map((item, index) => (
              <div // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.soundBar}
              />
            ))}
          </div>
          <p
            className={classNames(styles.playBlock__text, {
              [styles.hidden]: isPlaying || isStarted,
            })}
          >
            Play audio about xplobiz
          </p>
        </div>
      </section>
    </div>
  );
};

export default HomeAudio;
